import httpClient from './httpClient';
import axios from 'axios';

export class ItemClient {
    static cancelTokenSource;

    constructor() {
    }

    static async SearchItems(query, category) {
        try {
            let response = await httpClient.get("/items?SearchQuery=" + query + "&limit=10&CategoryId=" + category +"&limit=10");
            return response.data.data;
        } catch (ex) {
            return [];
        }
    }

    static async Search(categoryId, page, query, limit = 27) {

        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('Request canceled due to a new request.');
        }
        this.cancelTokenSource = axios.CancelToken.source();

        try {
           var result = await httpClient.get('/items?path=' + categoryId, {
                params: {
                    page: page,
                   searchQuery: query,
                   limit: limit

                },
                cancelToken: this.cancelTokenSource.token
            });
            return result.data;
        } catch (ex) {
            return {
                "data": [],
                "info": new Object()
            }
        }
    }
}