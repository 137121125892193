<template>
    <div class="box-header-search">
        <div class="form-search row" method="post" action="#">

            <div class="box-keysearch col-8">
                <input class="form-control font-xs"
                       type="text"
                       v-model="query"
                       v-on:click="focus = true"
                       v-on:blur="focus = false"
                       :placeholder="$t('search.searchArticle')"
                       v-on:keyup="$emit('SearchSubmitted')" 
                       ref="searchInput"/>
                <span class="clearSearch" v-if="query != null && query != ''" v-on:click="query='';$emit('SearchSubmitted')">
                    <font-awesome-icon icon="circle-xmark" />
                </span>
            </div>
            <div class="col-4">
                <input @click="$emit('SearchSubmitted')"
                       type="button"
                       class="btn btn-buy"
                       :value="$t('search.search')">
            </div>

            <div class="box-category col-12 mt-5" v-if="showCategories && query != ''">
                <select class="select-active form-control font-sm select-style1 color-gray-700" v-model="categoryId">
                    <option value="">{{ $t('search.allCategories') }}</option>
                    <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                </select>

                <select class="select-active form-control font-sm select-style1 color-gray-700 mt-5" v-model="childCategoryId">
                    <option value=""></option>
                    <optgroup v-for="category in childCategories" :key="category.id" :value="category.id" :label="category.name">
                        <option v-for="childCategory in category.categories" :key="childCategory.id" :value="childCategory.id">{{ childCategory.name }}</option>
                    </optgroup>
                </select>
            </div>
        </div>
    </div>
</template> 

<script>
    import httpClient from '../httpClients/httpClient';

    export default {
        name: 'Search',


        data() {
            return {
                categoryId: '',
                childCategoryId: '',

                categories: Array,
                query: '',

                focus: false,
                childCategories: Array,
                selectedCategory: '',

            }
        },
        props: ['showCategories'],
        methods: {
            loadCategories: function () {
                httpClient.get('/categories').then(result => {
                    this.categories = result.data.categories;

                });
            },
            setFocus: function () {
                this.$refs.searchInput.focus();
            }
        },
        mounted() {
            this.loadCategories();
        },
        watch: {
            categoryId: function (val) {
                var a = this.categories.find(x => x.id === val);
                this.childCategories = a.categories

                this.selectedCategory = val;
            },
            childCategoryId: function (val) {
                if (val == "") {
                    this.selectedCategory = this.categoryId
                    return;
                }
                this.selectedCategory = val;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .box-keysearch {
        position: relative;
    }

    .clearSearch {
        display: block;
        position: absolute;
        right: 25px;
        bottom: 12px;
    }
</style>

